@import './vars';
@import './mixins';

html {
  overflow-x: initial !important;
}

body,
html {
  overflow-x: hidden;
  width: 100%;
  // height: 100dvh;
  position: relative;
  box-sizing: border-box;
  box-sizing: border-box;
  min-width: 320px;
  scroll-behavior: smooth;
}

body {
  font-family: 'Gilroy';
  color: $text-color;
  font-size: 16px;
  line-height: 1.2;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  word-break: normal;
  background-color: #f4f8e7;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

/* stylelint-disable-next-line selector-max-id */
#root {
  width: 100%;
  height: 100%;
}

.app {
  position: relative;
}

.container {
  width: 100%;
  max-width: 1224px;
  height: 100%;
  @include flex(center, center);
  margin: 0 auto;
  position: relative;

  @include breakpoint('M') {
    max-width: 930px;
  }

  @include breakpoint('S') {
    max-width: 610px;
  }
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  // content: '';
}

.error_render {
  min-height: 100vh;
  @include flex(center, center);
}

img {
  display: block;
  width: 100%;
  height: auto;
  user-select: none;
}

svg {
  display: block;
}

cache {
  position: absolute;
  z-index: -1000;
  opacity: 0;
}

cache img {
  position: absolute;
}

.button {
  display: block;
  @include button-reset-style;
  width: fit-content;
  font-weight: 700;
  font-size: 24px;
  line-height: 133%;
  text-align: center;
  color: $white;
  border-radius: 73px;
  background-color: $green;
  padding: 19px 48px 17px;
  transition: background-color $default-transition;

  @include breakpoint('S') {
    font-size: 16px;
    padding: 16px 56px;
  }

  &:hover {
    background-color: $green-light;
  }
}
