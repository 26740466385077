@import '../../styles/base/vars';
@import '../../styles/base/mixins';

.game {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  min-height: 690px;

  @include breakpoint('S') {
    min-height: 650px;
  }
}

.game::after {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  content: url(../../images/like.svg), url(../../images/close.svg),
    url(../../images/final-ai-1.jpg), url(../../images/final-ai-2.jpg),
    url(../../images/final-loveis-1.jpg), url(../../images/final-loveis-2.jpg),
    url(../../images/final-penguins-1.jpg),
    url(../../images/final-penguins-2.jpg), url(../../images/words.jpg);
}

.wrap {
  width: 100%;
  height: 100%;
  padding-bottom: 4px;
  position: relative;

  @include breakpoint('S') {
    padding-bottom: 13px;
  }
}

.hidden {
  width: 100%;
  height: 100%;
  @include flex(center, center);
  flex-direction: column;
  overflow: hidden;
}
