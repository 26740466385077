@import '../../styles/base/vars';
@import '../../styles/base/mixins';

.footer {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  z-index: 20;

  @include breakpoint('XS') {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}

.wrap {
  width: 100%;
  @include flex(center, space-between);
  font-size: 12px;
  line-height: 130%;

  @include breakpoint('S') {
    font-size: 10px;
  }

  .vacancyLink,
  .careerLink {
    text-decoration: none;
    transition: color $default-transition;

    &:link,
    &:visited,
    &:active {
      color: $text-color;
    }

    &:hover {
      color: rgba($text-color, 0.8);
    }
  }
}

.left {
  @include flex(center, flex-start);

  span {
    display: block;
    margin: -1px 6px 0;

    @include breakpoint('S') {
      margin: -2px 5px 0;
    }
  }

  img {
    width: 18px;
    margin: 0 8px 0 4px;

    @include breakpoint('S') {
      width: 14px;
      margin: 0 3px 0 -1px;
      transform: translateY(-1px);
    }
  }
}
