/**
 * Variables
*/

/* Typography */
/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */
$black: #000;
$white: #fff;
$text: #252628;
$green: #67a511;
$green-light: #8bc540;

$text-color: $text;

$default-ease: cubic-bezier(0.33, 1, 0.68, 1);
$default-time: 0.4s;

$default-transition: $default-time $default-ease;

/* Components */
$breakpoints: (
  'XS': 700px,
  'S': 1020px,
  'M': 1340px,
) !default;
