@import '../../styles/base/vars';
@import '../../styles/base/mixins';

.rules {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  min-height: 690px;

  @include breakpoint('S') {
    min-height: 650px;
  }
}

.rules::after {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  content: url(../../images/choice-bg-1.jpg), url(../../images/choice-bg-2.jpg),
    url(../../images/choice-bg-3.jpg), url(../../images/choice-bg-4.jpg);
}

.wrap {
  width: 100%;
  min-height: 532px;
  @include flex(stretch, flex-end);
  flex-direction: column;

  @include breakpoint('S') {
    min-height: 450px;
    margin-bottom: 25px;
  }
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 124%;
  text-align: center;
  color: $green;

  @include breakpoint('S') {
    font-size: 22px;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 38px;

  @include breakpoint('S') {
    gap: 12px;
    margin-top: 24px;
  }

  @include breakpoint('XS') {
    grid-template-columns: 100%;
    padding: 0 20px;
  }
}

.wrapper {
  border-radius: 40px;
  box-shadow: -13px 5px 48px 0 rgba(140, 140, 118, 0.45);

  @include breakpoint('S') {
    border-radius: 16px;
  }
}

.item {
  min-height: 252px;
  @include flex(flex-start, space-between);
  flex-direction: column;
  border-radius: 40px;
  background-image: url(../../images/rules-bg.jpg);
  background-size: 392px;
  background-repeat: no-repeat;
  padding: 32px 32px 20px;
  position: relative;

  @include breakpoint('M') {
    min-height: 232px;
    padding: 32px 24px 20px;
  }

  @include breakpoint('S') {
    min-height: 200px;
    border-radius: 16px;
    padding: 20px;
  }

  @include breakpoint('XS') {
    min-height: auto;
    display: grid;
    grid-template-columns: 64px 1fr;
    background-size: cover;
    padding: 13.5px 11px 19px;
  }

  &::before {
    content: '';
    border-radius: 38px;
    border: 1px solid #dcdce2;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    pointer-events: none;

    @include breakpoint('S') {
      border-radius: 12px;
    }
  }

  p {
    min-height: 84px;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;

    @include breakpoint('M') {
      min-height: 80px;
      font-size: 20px;
    }

    @include breakpoint('S') {
      min-height: 77px;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.01em;
    }

    @include breakpoint('XS') {
      max-width: 330px;
      min-height: auto;
    }
  }
}

.item1 {
  .num {
    &::before {
      background-image: url(../../images/rules-arrow.svg);
      background-size: 14px 12px;

      @include breakpoint('S') {
        background-size: 10px 9x;
      }

      @include breakpoint('XS') {
        background-size: 7px 6px;
      }
    }
  }

  p {
    @media (max-width: 374px) {
      max-width: 195px;
    }
  }
}

.item2 {
  .num {
    &::before {
      background-image: url(../../images/rules-arrow.svg);
      background-size: 14px 12px;

      @include breakpoint('S') {
        background-size: 10px 9x;
      }

      @include breakpoint('XS') {
        background-size: 7px 6px;
      }
    }
  }

  p {
    @media (max-width: 374px) {
      max-width: 195px;
    }
  }
}

.item3 {
  &::after {
    content: '';
    width: 140px;
    height: 135px;
    background-image: url(../../images/tg.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -53px;
    right: 34px;

    @include breakpoint('M') {
      right: 0;
    }

    @include breakpoint('S') {
      right: -14px;
    }

    @include breakpoint('XS') {
      width: 110px;
      height: 106px;
      top: auto;
      bottom: -49px;
    }
  }

  .num {
    &::before {
      background-color: $green;
      background-image: url(../../images/rules-check.svg);
      background-size: 19px 14px;

      @include breakpoint('S') {
        background-size: 14px 10px;
      }

      @include breakpoint('XS') {
        background-size: 9px 6px;
      }
    }
  }

  p {
    @include breakpoint('XS') {
      width: 150px;
    }
  }
}

.num {
  position: relative;

  &::before {
    content: '';
    width: 56px;
    height: 56px;
    border: 1px solid $green;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 45px;

    @include breakpoint('S') {
      width: 40px;
      height: 40px;
      left: 32px;
    }

    @include breakpoint('XS') {
      width: 24px;
      height: 24px;
      border: 0.5px solid $green;
      left: 18px;
    }
  }

  span {
    width: 56px;
    height: 56px;
    @include flex(center, center);
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: $green;
    border-radius: 50%;
    background-color: #c4e88b;
    position: relative;
    z-index: 10;

    @include breakpoint('S') {
      width: 40px;
      height: 40px;
      font-size: 14px;
    }

    @include breakpoint('XS') {
      width: 24px;
      height: 24px;
      font-size: 11px;
    }
  }
}

.bottom {
  margin: 90px auto 0;
  position: relative;

  @include breakpoint('S') {
    margin: 108px auto 0;
  }

  @include breakpoint('XS') {
    margin: 68px auto 0;
  }
}

.slogan {
  position: absolute;
  top: -32px;
  left: -162px;

  @include breakpoint('S') {
    top: -5px;
    left: -91px;
  }

  &::before {
    content: '';
    width: 57px;
    height: 48px;
    background-image: url(../../images/home-arrow-1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -55px;
    left: 82px;

    @include breakpoint('S') {
      width: 36px;
      height: 31px;
      bottom: -31px;
      left: 42px;
    }
  }

  p {
    font-family: 'Pangolin';
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: $green;
    transform: rotate(-7deg);
    position: relative;
    z-index: 10;

    @include breakpoint('S') {
      font-size: 14px;
    }

    @media (max-width: 370px) {
      transform: rotate(-7deg) translateX(25px) translateY(-10px);
    }
  }
}
