@import '../../styles/base/vars';
@import '../../styles/base/mixins';

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.logo {
  width: 76px;
  height: 76px;
  border-radius: 0 0 12px 12px;
  background-color: $white;
  background-image: url(../../images/logo.svg);
  background-size: 32px 32px;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: -2px 1px 9px 0 rgba(140, 140, 118, 0.45);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;

  @include breakpoint('S') {
    width: 80px;
    height: 80px;
    border-radius: 0 0 13px 13px;
  }
}
