@import '../../styles/base/vars';
@import '../../styles/base/mixins';

.home {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  min-height: 690px;

  @include breakpoint('S') {
    min-height: 650px;
  }
}

.home::after {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  content: url(../../images/rules-bg.jpg), url(../../images/rules-arrow.svg),
    url(../../images/rules-check.svg);
}

.wrap {
  min-height: 532px;
  @include flex(center, flex-end);
  flex-direction: column;

  @include breakpoint('S') {
    min-height: 450px;
    margin-bottom: 25px;
  }
}

.top {
  position: relative;
}

.main {
  width: 585px;
  border-radius: 32px;
  background-color: $white;
  background-image: url(../../images/home-card-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: -13px 5px 48px 0 rgba(140, 140, 118, 0.59);
  padding: 77px 70px;
  margin: 0 auto;
  position: relative;
  z-index: 10;

  @include breakpoint('M') {
    width: 430px;
    padding: 77px 30px;
  }

  @include breakpoint('S') {
    width: 292px;
    height: 357px;
    @include flex(flex-start, space-between);
    flex-direction: column;
    border-radius: 33px;
    box-shadow: -11px 4px 40px 0 rgba(140, 140, 118, 0.45);
    padding: 21px 30px 15px;
  }

  @media (max-width: 370px) {
    width: 260px;
    padding: 21px 25px 15px;
  }

  &::before {
    content: '';
    border-radius: 28px;
    border: 1px solid #dcdce2;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    pointer-events: none;

    @include breakpoint('S') {
      border-radius: 29px;
      top: 4px;
      bottom: 4px;
      left: 4px;
      right: 4px;
      z-index: 10;
    }
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    text-align: center;
    margin-top: 30px;

    @include breakpoint('S') {
      font-size: 12px;
      line-height: 125%;
      margin-top: 0;
      position: relative;
      z-index: 10;
    }

    span {
      color: $green;
    }
  }
}

.image {
  display: none;

  @include breakpoint('S') {
    display: block;
    border-radius: 33px;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &::before {
      content: '';
      background-image: url(../../images/home-card-1-mob.png),
        url(../../images/home-card-bg.jpg);
      background-size: 100%, cover;
      background-position:
        top center,
        center left 57%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 1;
      animation: 4s linear infinite alternate bg1;

      @media (max-width: 370px) {
        background-position:
          top 10px center,
          center left 57%;
      }
    }

    &::after {
      content: '';
      background-image: url(../../images/home-card-2-mob.png),
        url(../../images/home-card-bg.jpg);
      background-size: 100%, cover;
      background-position:
        top center,
        center left 57%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      animation: 4s linear infinite alternate bg2;

      @media (max-width: 370px) {
        background-position:
          top 10px center,
          center left 57%;
      }
    }
  }
}

@keyframes bg1 {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bg2 {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.title {
  font-weight: 700;
  font-size: 56px;
  line-height: 90%;
  text-align: center;

  @include breakpoint('M') {
    font-size: 52px;
  }

  @include breakpoint('S') {
    font-size: 28px;
    position: relative;
    z-index: 10;
  }
}

.subtitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: $green;
  margin-top: 12px;

  @include breakpoint('S') {
    font-size: 13px;
    margin-top: 8px;
    position: relative;
    z-index: 10;
  }

  @media (max-width: 370px) {
    font-size: 12px;
  }
}

.card {
  width: 307px;
  height: 340px;
  border-radius: 36px;
  background-color: $white;
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: -12px 4px 43px 0 rgba(140, 140, 118, 0.45);
  padding: 30px 29px;
  position: absolute;

  @include breakpoint('S') {
    width: 221px;
    height: 294px;
    border-radius: 33px;
    box-shadow: -11px 4px 40px 0 rgba(140, 140, 118, 0.45);
    padding: 0;
  }

  &::before {
    content: '';
    border-radius: 31px;
    border: 1px solid #dcdce2;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    pointer-events: none;

    @include breakpoint('S') {
      display: none;
    }
  }

  p {
    font-weight: 700;
    font-size: 21px;
    line-height: 133%;

    @include breakpoint('S') {
      display: none;
    }
  }
}

.left {
  background-image: url(../../images/home-card-1.jpg);
  transform: rotate(-10deg);
  bottom: 16px;
  left: -214px;
  transition: left 0.6s;

  @include breakpoint('M') {
    left: -205px;
  }

  @include breakpoint('S') {
    background-image: none;
    transform: rotate(-15deg);
    bottom: 30px;
    left: 2px;
  }

  &:hover {
    left: -244px;

    @include breakpoint('M') {
      left: -235px;
    }

    @include breakpoint('S') {
      left: 2px;
    }
  }
}

.right {
  background-image: url(../../images/home-card-2.jpg);
  transform: rotate(10deg);
  bottom: 16px;
  right: -256px;
  transition: right 0.6s;

  @include breakpoint('M') {
    right: -236px;
  }

  @include breakpoint('S') {
    background-image: none;
    transform: rotate(15deg);
    bottom: 30px;
    right: 6px;
  }

  &:hover {
    right: -286px;

    @include breakpoint('M') {
      right: -266px;
    }

    @include breakpoint('S') {
      right: 6px;
    }
  }

  p {
    text-align: end;
  }
}

.bottom {
  margin-top: 66px;
  position: relative;

  @include breakpoint('S') {
    margin-top: 35px;
  }
}

.slogan {
  position: absolute;
  top: -25px;
  left: -141px;

  @include breakpoint('S') {
    top: -5px;
    left: -87px;
  }

  &::before {
    content: '';
    width: 57px;
    height: 48px;
    background-image: url(../../images/home-arrow-1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -48px;
    left: 62px;

    @include breakpoint('S') {
      width: 36px;
      height: 31px;
      bottom: -31px;
      left: 38px;
    }
  }

  p {
    font-family: 'Pangolin';
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: $green;
    transform: rotate(-7deg);
    position: relative;
    z-index: 10;

    @include breakpoint('S') {
      font-size: 14px;
    }

    @media (max-width: 370px) {
      transform: rotate(-7deg) translateX(20px);
    }
  }
}

.stickerpack {
  position: absolute;
  top: 13px;
  right: -312px;

  @include breakpoint('S') {
    top: 70px;
    right: -60px;
  }

  &::before {
    content: '';
    width: 84px;
    height: 84px;
    background-image: url(../../images/home-arrow-2.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -62px;
    left: -88px;

    @include breakpoint('S') {
      width: 39px;
      height: 39px;
      transform: rotate(-94deg) scale(-1, 1);
      bottom: 10px;
      left: auto;
      right: 21px;
    }
  }

  &::after {
    content: '';
    width: 140px;
    height: 135px;
    background-image: url(../../images/tg.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -64px;
    left: 67px;

    @include breakpoint('S') {
      width: 108px;
      height: 104px;
      top: -23px;
      left: -92px;
    }
  }

  p {
    width: 200px;
    font-family: 'Pangolin';
    font-size: 18px;
    line-height: 110%;
    letter-spacing: 0.03em;
    color: $green;
    transform: rotate(-7deg);
    position: relative;
    z-index: 10;

    @include breakpoint('S') {
      width: 160px;
      font-size: 14px;
      padding-right: 20px;
    }

    span {
      color: $green-light;
    }
  }
}
