@import '../../styles/base/vars';
@import '../../styles/base/mixins';

.choice {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  min-height: 690px;

  @include breakpoint('S') {
    min-height: 650px;
  }
}

.choice::after {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  content: url(../../images/game-text-bg.jpg);
}

.wrap {
  width: 100%;
  min-height: 532px;
  @include flex(stretch, flex-end);
  flex-direction: column;
  padding-bottom: 123px;

  @include breakpoint('M') {
    padding-bottom: 168px;
  }

  @include breakpoint('S') {
    min-height: 450px;
    padding-bottom: 24px;
    margin-bottom: 85px;
    position: relative;
  }

  @include breakpoint('XS') {
    margin-bottom: 24px;
  }

  @media (max-width: 374px) {
    margin-bottom: 135px;
  }
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 124%;
  text-align: center;
  color: $green;

  @include breakpoint('S') {
    font-size: 22px;
    line-height: 133%;
  }

  @include breakpoint('XS') {
    max-width: 300px;
    margin: 0 auto;
  }
}

.list {
  @include flex(flex-start, space-between);
  margin-top: 36px;

  @include breakpoint('S') {
    max-width: 310px;
    margin: 3px auto 0;
  }

  @media (max-width: 374px) {
    max-width: 290px;
    margin: 10px auto 0;
  }
}

.wrapper {
  @include breakpoint('S') {
    border-radius: 20px;
    box-shadow: -13px 5px 48px 0 rgba(140, 140, 118, 0.45);
    margin-top: 17px;

    @media (max-width: 374px) {
      margin-top: 10px;
    }
  }
}

.item {
  @include button-reset-style;
  width: 288px;
  height: 288px;
  @include flex(center, flex-end);
  flex-direction: column;
  border-radius: 40px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 24px;
  position: relative;
  transition: box-shadow $default-transition;

  @include breakpoint('M') {
    width: 224px;
    height: 224px;
    padding: 16px;
  }

  @include breakpoint('S') {
    width: 146px;
    height: 146px;
    border-radius: 20px;
    padding: 12px;
  }

  @media (max-width: 374px) {
    width: 140px;
    height: 140px;
  }

  &:hover {
    box-shadow: -13px 5px 48px 0 rgba(140, 140, 118, 0.45);

    @include breakpoint('S') {
      box-shadow: none;
    }

    span {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    border-radius: 38px;
    border: 1px solid #dcdce2;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    pointer-events: none;

    @include breakpoint('S') {
      border-radius: 19px;
      border: 0.5px solid #dcdce2;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
    }
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;

    @include breakpoint('S') {
      font-size: 7px;
    }
  }

  span {
    display: block;
    font-family: 'Pangolin';
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.04em;
    text-align: start;
    color: $green;
    transform: rotate(-15deg);
    position: absolute;
    bottom: -108px;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity $default-transition;

    @include breakpoint('M') {
      font-size: 20px;
      bottom: -90px;
    }

    @include breakpoint('S') {
      display: none;
    }

    &::before {
      content: '';
      width: 57px;
      height: 48px;
      background-image: url(../../images/home-arrow-1.svg);
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(-75deg);
      position: absolute;
      bottom: -4px;
      right: -60px;

      @include breakpoint('M') {
        right: -40px;
      }
    }
  }
}

.item1 {
  background-image: url(../../images/choice-bg-1.jpg);
}

.item2 {
  background-image: url(../../images/choice-bg-2.jpg);
}

.item3 {
  background-image: url(../../images/choice-bg-3.jpg);
}

.item4 {
  background-image: url(../../images/choice-bg-4.jpg);
}

.bottom {
  width: fit-content;
  position: absolute;
  bottom: -39px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateX(-55px);

  &::before {
    content: '';
    width: 36px;
    height: 42px;
    background-image: url(../../images/home-arrow-1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    position: absolute;
    bottom: -11px;
    right: -27px;
  }

  p {
    font-family: 'Pangolin';
    font-size: 14px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: $green;
    transform: rotate(-7deg);
  }
}
