@import '../../../styles/base/vars';
@import '../../../styles/base/mixins';

.cards {
  min-width: 388px;
  position: relative;

  @include breakpoint('S') {
    min-width: 336px;
  }

  @media (max-width: 370px) {
    min-width: 304px;
  }

  :global(.swiper) {
    width: 345px;
    height: 460px;
    margin: 0 auto;
    overflow: visible;

    @include breakpoint('S') {
      width: 303px;
      height: 404px;
    }

    @media (max-width: 370px) {
      width: 270px;
      height: 360px;
    }
  }

  :global(.swiper-slide) {
    opacity: 0;
  }

  :global(.swiper-slide-active) {
    opacity: 1;
  }

  :global(.swiper-slide-prev) {
    opacity: 1;
  }

  :global(.swiper-slide-next) {
    opacity: 1;
  }
}

.top {
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #737373;
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;

  @include breakpoint('S') {
    top: -47px;
  }
}

.card {
  height: 100%;
  @include flex(flex-start, space-between);
  flex-direction: column;
  border-radius: 40px;
  background-color: $white;
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: -13px 5px 48px 0 rgba(140, 140, 118, 0.45);
  padding: 32px;
  overflow: hidden;
  position: relative;

  @include breakpoint('S') {
    border-radius: 35px;
    box-shadow: -11px 4px 43px 0 rgba(140, 140, 118, 0.45);
    padding: 24px;
  }

  @media (max-width: 370px) {
    padding: 24px 20px;
  }

  &::before {
    content: '';
    border-radius: 35px;
    border: 1px solid #dcdce2;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    pointer-events: none;

    @include breakpoint('S') {
      border-radius: 31px;
      top: 4px;
      bottom: 4px;
      left: 4px;
      right: 4px;
    }
  }

  &::after {
    content: '';
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: $white;
    background-image: url(../../../images/logo.svg);
    background-size: 18px 17px;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 16px 40px 0 rgba(47, 68, 78, 0.39);
    position: absolute;
    top: 20px;
    right: 20px;

    @include breakpoint('S') {
      width: 32px;
      height: 32px;
      background-size: 16px 16px;
      box-shadow: 0 14px 35px 0 rgba(47, 68, 78, 0.39);
      top: 16px;
      right: 17px;
    }
  }
}

.cardIsLiked {
  background-color: #fffae0;
}

.cardIsDisliked {
  background-color: #e3e3e3;
}

.cardIsLiked.words,
.cardIsDisliked.words {
  background-image: none;
}

.title {
  width: 90%;
  font-weight: 700;
  font-size: 24px;
  line-height: 133%;

  @include breakpoint('S') {
    font-size: 20px;
    line-height: 150%;
  }
}

.slogan {
  font-family: 'Pangolin';
  font-size: 29px;
  line-height: 130%;
  transform: rotate(-2deg);

  @include breakpoint('S') {
    font-size: 24px;
  }

  span {
    color: #95c123;
  }
}

.text {
  text-align: center;
  margin: 0 auto;

  p {
    min-height: 58px;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 12px;

    @include breakpoint('S') {
      min-height: 51px;
      font-size: 14px;
      margin-bottom: 17px;
    }

    @media (max-width: 370px) {
      min-height: 44px;
      font-size: 12px;
      margin-bottom: 12px;
    }
  }

  .author {
    display: block;
    font-size: 12px;
    line-height: 120%;

    @include breakpoint('S') {
      font-size: 10px;
      line-height: 130%;
    }
  }
}

.buttons {
  @include flex(center, center);
  margin-top: 30px;
  position: relative;

  @include breakpoint('S') {
    margin-top: 32px;
  }
}

.button {
  @include button-reset-style;
  width: 73px;
  height: 73px;
  @include flex(center, center);
  border-radius: 50%;
  border: 3.5px solid #dcdce2;
  background-color: $white;
  margin: 0 12px;
  transition: transform $default-transition;

  @include breakpoint('S') {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 370px) {
    margin: 0 8px;
  }

  &:hover {
    transform: scale(1.05);
  }

  img,
  svg {
    width: 36px;
    height: 36px;

    @include breakpoint('S') {
      width: 30px;
      height: 30px;
    }
  }
}

.buttonIsLiked {
  border: 3.5px solid #ff4040;
  background-color: #ff4040;
  pointer-events: none;

  svg {
    path {
      fill: $white;
    }
  }
}

.buttonIsDisliked {
  border: 3.5px solid #737373;
  background-color: #737373;
  pointer-events: none;

  svg {
    path {
      stroke: $white;
    }
  }
}

.topClue {
  position: absolute;
  top: 17px;
  left: -116px;
  z-index: 20;

  @include breakpoint('S') {
    top: -23px;
    left: -5px;
  }

  @media (max-width: 370px) {
    left: 0;
  }

  &::before {
    content: '';
    width: 38px;
    height: 32px;
    background-image: url(../../../images/home-arrow-1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(218deg) scale(-1, 1);
    position: absolute;
    top: -22px;
    right: -16px;

    @include breakpoint('S') {
      width: 27px;
      height: 22px;
      transform: rotate(264deg) scale(-1, 1);
      top: -7px;
      right: 17px;
    }
  }

  p {
    width: 104px;
    font-family: 'Pangolin';
    font-size: 19px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: $green;
    transform: rotate(16deg);

    @include breakpoint('S') {
      font-size: 12px;
      transform: rotate(-10deg);
    }
  }
}

.topClueDislike {
  p {
    transform: rotate(16deg) translateY(-15px);

    @include breakpoint('S') {
      transform: rotate(-10deg) translateX(4px) translateY(-11px);
    }
  }
}

.love-ai1 {
  background-image: url(../../../images/cards/love-1-1.png);
}

.love-ai2 {
  background-image: url(../../../images/cards/love-1-2.png);
}

.love-ai3 {
  background-image: url(../../../images/cards/love-1-3.png);
}

.love-ai4 {
  background-image: url(../../../images/cards/love-1-4.png);
}

.love-ai5 {
  background-image: url(../../../images/cards/love-1-5.png);
}

.love-ai6 {
  background-image: url(../../../images/cards/love-1-6.png);
}

.love-loveis1 {
  background-image: url(../../../images/cards/love-2-1.png);
}

.love-loveis2 {
  background-image: url(../../../images/cards/love-2-2.png);
}

.love-loveis3 {
  background-image: url(../../../images/cards/love-2-3.png);
}

.love-loveis4 {
  background-image: url(../../../images/cards/love-2-4.png);
}

.love-loveis5 {
  background-image: url(../../../images/cards/love-2-5.png);
}

.love-loveis6 {
  background-image: url(../../../images/cards/love-2-6.png);
}

.love-penguins1 {
  background-image: url(../../../images/cards/love-3-1.png);
}

.love-penguins2 {
  background-image: url(../../../images/cards/love-3-2.png);
}

.love-penguins3 {
  background-image: url(../../../images/cards/love-3-3.png);
}

.love-penguins4 {
  background-image: url(../../../images/cards/love-3-4.png);
}

.love-penguins5 {
  background-image: url(../../../images/cards/love-3-5.png);
}

.love-penguins6 {
  background-image: url(../../../images/cards/love-3-6.png);
}

.kindness-ai1 {
  background-image: url(../../../images/cards/kindness-1-1.png);
}

.kindness-ai2 {
  background-image: url(../../../images/cards/kindness-1-2.png);
}

.kindness-ai3 {
  background-image: url(../../../images/cards/kindness-1-3.png);
}

.kindness-ai4 {
  background-image: url(../../../images/cards/kindness-1-4.png);
}

.kindness-ai5 {
  background-image: url(../../../images/cards/kindness-1-5.png);
}

.kindness-ai6 {
  background-image: url(../../../images/cards/kindness-1-6.png);
}

.kindness-loveis1 {
  background-image: url(../../../images/cards/kindness-2-1.png);
}

.kindness-loveis2 {
  background-image: url(../../../images/cards/kindness-2-2.png);
}

.kindness-loveis3 {
  background-image: url(../../../images/cards/kindness-2-3.png);
}

.kindness-loveis4 {
  background-image: url(../../../images/cards/kindness-2-4.png);
}

.kindness-loveis5 {
  background-image: url(../../../images/cards/kindness-2-5.png);
}

.kindness-loveis6 {
  background-image: url(../../../images/cards/kindness-2-6.png);
}

.kindness-penguins1 {
  background-image: url(../../../images/cards/kindness-3-1.png);
}

.kindness-penguins2 {
  background-image: url(../../../images/cards/kindness-3-2.png);
}

.kindness-penguins3 {
  background-image: url(../../../images/cards/kindness-3-3.png);
}

.kindness-penguins4 {
  background-image: url(../../../images/cards/kindness-3-4.png);
}

.kindness-penguins5 {
  background-image: url(../../../images/cards/kindness-3-5.png);
}

.kindness-penguins6 {
  background-image: url(../../../images/cards/kindness-3-6.png);
}

.trust-ai1 {
  background-image: url(../../../images/cards/trust-1-1.png);
}

.trust-ai2 {
  background-image: url(../../../images/cards/trust-1-2.png);
}

.trust-ai3 {
  background-image: url(../../../images/cards/trust-1-3.png);
}

.trust-ai4 {
  background-image: url(../../../images/cards/trust-1-4.png);
}

.trust-ai5 {
  background-image: url(../../../images/cards/trust-1-5.png);
}

.trust-ai6 {
  background-image: url(../../../images/cards/trust-1-6.png);
}

.trust-loveis1 {
  background-image: url(../../../images/cards/trust-2-1.png);
}

.trust-loveis2 {
  background-image: url(../../../images/cards/trust-2-2.png);
}

.trust-loveis3 {
  background-image: url(../../../images/cards/trust-2-3.png);
}

.trust-loveis4 {
  background-image: url(../../../images/cards/trust-2-4.png);
}

.trust-loveis5 {
  background-image: url(../../../images/cards/trust-2-5.png);
}

.trust-loveis6 {
  background-image: url(../../../images/cards/trust-2-6.png);
}

.trust-penguins1 {
  background-image: url(../../../images/cards/trust-3-1.png);
}

.trust-penguins2 {
  background-image: url(../../../images/cards/trust-3-2.png);
}

.trust-penguins3 {
  background-image: url(../../../images/cards/trust-3-3.png);
}

.trust-penguins4 {
  background-image: url(../../../images/cards/trust-3-4.png);
}

.trust-penguins5 {
  background-image: url(../../../images/cards/trust-3-5.png);
}

.trust-penguins6 {
  background-image: url(../../../images/cards/trust-3-6.png);
}

.understanding-ai1 {
  background-image: url(../../../images/cards/understanding-1-1.png);
}

.understanding-ai2 {
  background-image: url(../../../images/cards/understanding-1-2.png);
}

.understanding-ai3 {
  background-image: url(../../../images/cards/understanding-1-3.png);
}

.understanding-ai4 {
  background-image: url(../../../images/cards/understanding-1-4.png);
}

.understanding-ai5 {
  background-image: url(../../../images/cards/understanding-1-5.png);
}

.understanding-ai6 {
  background-image: url(../../../images/cards/understanding-1-6.png);
}

.understanding-loveis1 {
  background-image: url(../../../images/cards/understanding-2-1.png);
}

.understanding-loveis2 {
  background-image: url(../../../images/cards/understanding-2-2.png);
}

.understanding-loveis3 {
  background-image: url(../../../images/cards/understanding-2-3.png);
}

.understanding-loveis4 {
  background-image: url(../../../images/cards/understanding-2-4.png);
}

.understanding-loveis5 {
  background-image: url(../../../images/cards/understanding-2-5.png);
}

.understanding-loveis6 {
  background-image: url(../../../images/cards/understanding-2-6.png);
}

.understanding-penguins1 {
  background-image: url(../../../images/cards/understanding-3-1.png);
}

.understanding-penguins2 {
  background-image: url(../../../images/cards/understanding-3-2.png);
}

.understanding-penguins3 {
  background-image: url(../../../images/cards/understanding-3-3.png);
}

.understanding-penguins4 {
  background-image: url(../../../images/cards/understanding-3-4.png);
}

.understanding-penguins5 {
  background-image: url(../../../images/cards/understanding-3-5.png);
}

.understanding-penguins6 {
  background-image: url(../../../images/cards/understanding-3-6.png);
}

.loyalty-ai1 {
  background-image: url(../../../images/cards/loyalty-1-1.png);
}

.loyalty-ai2 {
  background-image: url(../../../images/cards/loyalty-1-2.png);
}

.loyalty-ai3 {
  background-image: url(../../../images/cards/loyalty-1-3.png);
}

.loyalty-ai4 {
  background-image: url(../../../images/cards/loyalty-1-4.png);
}

.loyalty-ai5 {
  background-image: url(../../../images/cards/loyalty-1-5.png);
}

.loyalty-ai6 {
  background-image: url(../../../images/cards/loyalty-1-6.png);
}

.loyalty-loveis1 {
  background-image: url(../../../images/cards/loyalty-2-1.png);
}

.loyalty-loveis2 {
  background-image: url(../../../images/cards/loyalty-2-2.png);
}

.loyalty-loveis3 {
  background-image: url(../../../images/cards/loyalty-2-3.png);
}

.loyalty-loveis4 {
  background-image: url(../../../images/cards/loyalty-2-4.png);
}

.loyalty-loveis5 {
  background-image: url(../../../images/cards/loyalty-2-5.png);
}

.loyalty-loveis6 {
  background-image: url(../../../images/cards/loyalty-2-6.png);
}

.loyalty-penguins1 {
  background-image: url(../../../images/cards/loyalty-3-1.png);
}

.loyalty-penguins2 {
  background-image: url(../../../images/cards/loyalty-3-2.png);
}

.loyalty-penguins3 {
  background-image: url(../../../images/cards/loyalty-3-3.png);
}

.loyalty-penguins4 {
  background-image: url(../../../images/cards/loyalty-3-4.png);
}

.loyalty-penguins5 {
  background-image: url(../../../images/cards/loyalty-3-5.png);
}

.loyalty-penguins6 {
  background-image: url(../../../images/cards/loyalty-3-6.png);
}

.respect-ai1 {
  background-image: url(../../../images/cards/respect-1-1.png);
}

.respect-ai2 {
  background-image: url(../../../images/cards/respect-1-2.png);
}

.respect-ai3 {
  background-image: url(../../../images/cards/respect-1-3.png);
}

.respect-ai4 {
  background-image: url(../../../images/cards/respect-1-4.png);
}

.respect-ai5 {
  background-image: url(../../../images/cards/respect-1-5.png);
}

.respect-ai6 {
  background-image: url(../../../images/cards/respect-1-6.png);
}

.respect-loveis1 {
  background-image: url(../../../images/cards/respect-2-1.png);
}

.respect-loveis2 {
  background-image: url(../../../images/cards/respect-2-2.png);
}

.respect-loveis3 {
  background-image: url(../../../images/cards/respect-2-3.png);
}

.respect-loveis4 {
  background-image: url(../../../images/cards/respect-2-4.png);
}

.respect-loveis5 {
  background-image: url(../../../images/cards/respect-2-5.png);
}

.respect-loveis6 {
  background-image: url(../../../images/cards/respect-2-6.png);
}

.respect-penguins1 {
  background-image: url(../../../images/cards/respect-3-1.png);
}

.respect-penguins2 {
  background-image: url(../../../images/cards/respect-3-2.png);
}

.respect-penguins3 {
  background-image: url(../../../images/cards/respect-3-3.png);
}

.respect-penguins4 {
  background-image: url(../../../images/cards/respect-3-4.png);
}

.respect-penguins5 {
  background-image: url(../../../images/cards/respect-3-5.png);
}

.respect-penguins6 {
  background-image: url(../../../images/cards/respect-3-6.png);
}

.words {
  background-image: url(../../../images/words.jpg);
  background-size: cover;
}
