@import '../../styles/base/vars';
@import '../../styles/base/mixins';

.final {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  min-height: 690px;
  position: relative;

  @include breakpoint('S') {
    min-height: 650px;
  }
}

.finalBlur {
  &::before {
    content: '';
    backdrop-filter: blur(6px);
    background-color: rgba(115, 115, 115, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
  }
}

.wrap {
  width: 100%;
  min-height: 532px;
  @include flex(center, center);
  flex-direction: column;
  margin-bottom: 52px;

  @include breakpoint('S') {
    min-height: 660px;
    margin-bottom: 0;
    overflow: hidden;
  }
}

.content {
  position: relative;

  @include breakpoint('S') {
    min-height: 493px;
    margin-top: 55px;
  }
}

.main {
  width: 585px;
  border-radius: 32px;
  background-color: $white;
  background-image: url(../../images/home-card-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: -13px 5px 48px 0 rgba(140, 140, 118, 0.59);
  padding: 26px 40px 30px;
  margin: 0 auto;
  position: relative;
  z-index: 10;

  @include breakpoint('M') {
    width: 430px;
  }

  @include breakpoint('S') {
    width: 311px;
    border-radius: 33px;
    box-shadow: -11px 4px 40px 0 rgba(140, 140, 118, 0.45);
    padding: 28px 20px 21px;
  }

  @media (max-width: 370px) {
    width: 290px;
  }

  &::before {
    content: '';
    border-radius: 28px;
    border: 1px solid #dcdce2;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    pointer-events: none;

    @include breakpoint('S') {
      border-radius: 29px;
      top: 4px;
      bottom: 4px;
      left: 4px;
      right: 4px;
    }
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    text-align: center;
    margin-top: 12px;

    @include breakpoint('M') {
      font-size: 14px;
    }

    @include breakpoint('S') {
      max-width: 255px;
      font-size: 12px;
      line-height: 125%;
      margin: 8px auto 0;
    }

    br {
      @include breakpoint('S') {
        display: none;
      }
    }
  }
}

.icon {
  width: 65px;
  height: 65px;
  @include flex(center, center);
  border-radius: 50%;
  border: 3.5px solid #dcdce2;
  background-color: $white;
  margin: 0 auto;

  @include breakpoint('M') {
    width: 60px;
    height: 60px;
  }

  @include breakpoint('S') {
    display: none;
  }

  img {
    width: 34px;

    @include breakpoint('M') {
      width: 30px;
    }
  }
}

.title {
  font-weight: 700;
  font-size: 56px;
  line-height: 90%;
  text-align: center;
  margin-top: 20px;

  @include breakpoint('M') {
    font-size: 48px;
    margin-top: 16px;
  }

  @include breakpoint('S') {
    font-size: 28px;
    margin-top: 0;
  }
}

.subtitle {
  max-width: 300px;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: $green;
  margin: 12px auto 0;

  @include breakpoint('M') {
    max-width: 270px;
    font-size: 18px;
  }

  @include breakpoint('S') {
    max-width: 210px;
    font-size: 13px;
    margin: 28px auto 0;
  }
}

.button {
  padding: 16px 56px;
  margin: 14px auto 0;

  @include breakpoint('M') {
    font-size: 20px;
  }

  @include breakpoint('S') {
    width: 100%;
    font-size: 16px;
    margin: 24px auto 0;
  }
}

.card {
  width: 307px;
  height: 340px;
  @include flex(flex-start, space-between);
  flex-direction: column;
  border-radius: 36px;
  background-color: $white;
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: -12px 4px 43px 0 rgba(140, 140, 118, 0.45);
  padding: 30px 29px 23px;
  position: absolute;

  &::before {
    content: '';
    border-radius: 31px;
    border: 1px solid #dcdce2;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    pointer-events: none;

    @include breakpoint('S') {
      border: 0.5px solid #dcdce2;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
    }
  }
}

.left {
  transform: rotate(-10deg);
  bottom: 25px;
  left: -214px;
  transition: left 0.6s;

  @include breakpoint('M') {
    left: -205px;
  }

  @include breakpoint('S') {
    width: 142px;
    height: 157px;
    border-radius: 17px;
    box-shadow: -5px 2px 20px 0 rgba(140, 140, 118, 0.45);
    padding: 13px 13px 10px;
    bottom: 14px;
    left: 13px;
  }

  &:hover {
    left: -244px;

    @include breakpoint('M') {
      left: -235px;
    }

    @include breakpoint('S') {
      left: 13px;
    }
  }

  &::before {
    @include breakpoint('S') {
      border-radius: 14px;
    }
  }

  .cardTitle {
    p {
      @include breakpoint('S') {
        font-size: 10px;
      }
    }
  }

  .slogan {
    @include breakpoint('S') {
      font-size: 12px;
      margin: 0 0 2px 4px;
    }
  }

  .text {
    .author {
      @include breakpoint('S') {
        font-size: 7px;
      }
    }
  }
}

.right {
  transform: rotate(10deg);
  bottom: 25px;
  right: -256px;
  transition: right 0.6s;

  @include breakpoint('M') {
    right: -236px;
  }

  @include breakpoint('S') {
    width: 121px;
    height: 134px;
    border-radius: 14px;
    background-position: top 6px left 6px;
    box-shadow: -5px 2px 17px 0 rgba(140, 140, 118, 0.45);
    padding: 11px 11px 8px;
    bottom: -11px;
    right: 73px;
  }

  &:hover {
    right: -286px;

    @include breakpoint('M') {
      right: -266px;
    }

    @include breakpoint('S') {
      right: 73px;
    }
  }

  &::before {
    @include breakpoint('S') {
      border-radius: 12px;
    }
  }

  .cardTitle {
    align-self: flex-end;

    @include breakpoint('S') {
      align-self: flex-start;
    }

    p {
      @include breakpoint('S') {
        font-size: 9px;
      }
    }
  }

  .slogan {
    margin: 0 0 23px 4px;

    @include breakpoint('S') {
      font-size: 10px;
      margin: 0 0 2px 4px;
    }
  }

  .text {
    .author {
      @include breakpoint('S') {
        font-size: 6px;
      }
    }
  }
}

.right.words {
  .cardTitle {
    align-self: flex-start;
  }
}

.ai-1 {
  background-image: url(../../images/final-ai-1.jpg);
}

.ai-2 {
  background-image: url(../../images/final-ai-2.jpg);
}

.loveis-1 {
  background-image: url(../../images/final-loveis-1.jpg);
}

.loveis-2 {
  background-image: url(../../images/final-loveis-2.jpg);
}

.penguins-1 {
  background-image: url(../../images/final-penguins-1.jpg);
}

.penguins-2 {
  background-image: url(../../images/final-penguins-2.jpg);
}

.words {
  background-image: url(../../images/final-words.jpg);
  background-size: cover !important;
  background-position: center !important;
}

.cardTitle {
  p {
    font-weight: 700;
    font-size: 21px;
    line-height: 133%;
  }
}

.slogan {
  font-family: 'Pangolin';
  font-size: 25px;
  line-height: 130%;
  transform: rotate(-2deg);
  margin: 0 0 23px 8px;

  span {
    color: #95c123;
  }
}

.text {
  text-align: center;
  margin: 0 auto;

  .author {
    display: block;
    font-size: 12px;
    line-height: 120%;
  }
}

.stickerpack {
  display: block;
  width: fit-content;
  position: absolute;
  bottom: -91px;
  left: 60px;
  right: 0;
  margin: 0 auto;
  pointer-events: none;

  @include breakpoint('S') {
    bottom: 125px;
    left: auto;
    right: -6px;
  }

  &::before {
    content: '';
    width: 64px;
    height: 64px;
    background-image: url(../../images/home-arrow-2.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(139deg);
    position: absolute;
    bottom: -13px;
    left: -63px;

    @include breakpoint('S') {
      width: 50px;
      height: 50px;
      bottom: -8px;
      left: -50px;
    }
  }

  &::after {
    content: '';
    width: 140px;
    height: 135px;
    background-image: url(../../images/tg.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -69px;
    right: -34px;

    @include breakpoint('S') {
      width: 102px;
      height: 107px;
      bottom: -51px;
      right: -6px;
    }
  }

  p {
    width: 170px;
    font-family: 'Pangolin';
    font-size: 22px;
    line-height: 110%;
    letter-spacing: 0.03em;
    color: $green;
    transform: rotate(-7deg);
    pointer-events: auto;
    transition: transform $default-transition;
    position: relative;
    z-index: 10;

    @include breakpoint('S') {
      width: 150px;
      font-size: 18px;
    }

    &:hover {
      transform: rotate(-7deg) scale(1.02);
    }

    span {
      color: $green-light;
    }
  }
}

.stickerpackWithoutStickers {
  @include breakpoint('S') {
    bottom: 140px;
    right: -32px;
  }

  &::before {
    @media (max-width: 370px) {
      width: 40px;
      height: 40px;
      bottom: 7px;
      left: -29px;
    }
  }

  &::after {
    @media (max-width: 370px) {
      bottom: -55px;
      right: 6px;
    }
  }

  p {
    @media (max-width: 370px) {
      font-size: 16px;
    }
  }
}

.stickersButton {
  @include button-reset-style;
  position: absolute;
  bottom: 19px;
  left: 59px;
  pointer-events: none;

  &::before {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1.5px solid #dcdce2;
    background-color: $white;
    background-image: url(../../images/like.svg);
    background-size: 21px 19px;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: -2px 1px 16px 0 rgba(140, 140, 118, 0.45);
    transform: rotate(-13deg);
    position: absolute;
    top: 6px;
    left: -48px;
  }

  &::after {
    content: '';
    width: 50px;
    height: 50px;
    background-image: url(../../images/home-arrow-1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(175deg);
    position: absolute;
    top: -12px;
    right: -32px;
  }

  p {
    width: 100px;
    font-family: 'Pangolin';
    font-size: 18px;
    line-height: 110%;
    letter-spacing: 0.03em;
    text-align: start;
    color: $green;
    transform: rotate(-7deg);
    pointer-events: auto;
    transition: transform $default-transition;
    position: relative;
    z-index: 10;

    &:hover {
      transform: rotate(-7deg) scale(1.02);
    }

    span {
      color: $green-light;
    }
  }
}

.stickers {
  @include breakpoint('S') {
    display: none;
  }
}

.stickersMob {
  border-radius: 33px;
  background-color: $white;
  box-shadow: -11px 4px 40px 0 rgba(140, 140, 118, 0.45);
  padding: 24px 20px 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;

  .button {
    margin: 0 auto;
  }
}

.stickersMobTop {
  top: 33px;
}

.popupTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.01em;
  text-align: center;
}

.close {
  @include button-reset-style;
  width: 28px;
  height: 28px;
  background-image: url(../../images/close.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 100;
}

.list {
  @include flex(flex-start, center);
  margin-top: 15px;
}

.wrapper {
  width: fit-content;
  border-radius: 24px;
  box-shadow: -13px 5px 48px 0 rgba(140, 140, 118, 0.45);
  position: absolute;

  @include breakpoint('S') {
    border-radius: 13px;
    box-shadow: -2px 1px 6px 0 rgba(70, 70, 62, 0.15);
    margin: 0 8px 16px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
  }
}

.sticker {
  display: block;
  width: 194px;
  height: 194px;
  @include button-reset-style;
  @include flex(center, center);
  border-radius: 24px;
  background-color: $white;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  transition: transform $default-transition;

  @include breakpoint('S') {
    width: 106px;
    height: 106px;
    border-radius: 13px;
  }

  &:hover {
    transform: scale(1.02);
  }

  img {
    width: 80%;
    filter: drop-shadow(-2px 1px 8px #8c8c7673);

    @include breakpoint('S') {
      filter: drop-shadow(-1px 1px 4px #8c8c7673);
    }
  }

  &::before {
    content: '';
    border-radius: 20px;
    border: 1px solid #dcdce2;
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;

    @include breakpoint('S') {
      border-radius: 11px;
      border: 0.5px solid #dcdce2;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
    }
  }
}

.stickerWords {
  background-size: 100%;

  .stickerText {
    top: auto;
    bottom: 16px;
    left: auto;
    right: 16px;

    @include breakpoint('S') {
      top: auto;
      bottom: 7px;
      left: auto;
      right: 7px;
    }
  }
}

.stickerText {
  font-family: 'Pangolin';
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.04em;
  color: $green;
  position: absolute;
  top: 16px;
  left: 16px;

  @include breakpoint('S') {
    font-size: 12px;
    top: 7px;
    left: 7px;
  }
}

.stickers1 {
  .wrapper1 {
    top: 140px;
    left: -225px;

    @include breakpoint('M') {
      left: -215px;
    }
  }
}

.stickers2 {
  .wrapper1 {
    top: 140px;
    left: -225px;

    @include breakpoint('M') {
      left: -215px;
    }
  }

  .wrapper2 {
    top: 140px;
    right: -225px;

    @include breakpoint('M') {
      right: -215px;
    }
  }
}

.stickers3 {
  .wrapper1 {
    top: 35px;
    left: -320px;

    @include breakpoint('M') {
      left: -240px;
    }
  }

  .wrapper2 {
    top: 140px;
    right: -225px;

    @include breakpoint('M') {
      right: -215px;
    }
  }

  .wrapper3 {
    top: 237px;
    left: -225px;

    @include breakpoint('M') {
      left: -215px;
    }
  }
}

.stickers4 {
  .wrapper1 {
    top: 35px;
    left: -320px;

    @include breakpoint('M') {
      left: -240px;
    }
  }

  .wrapper2 {
    top: 35px;
    right: -320px;

    @include breakpoint('M') {
      right: -240px;
    }
  }

  .wrapper3 {
    top: 237px;
    left: -225px;

    @include breakpoint('M') {
      left: -215px;
    }
  }

  .wrapper4 {
    top: 237px;
    right: -225px;

    @include breakpoint('M') {
      right: -215px;
    }
  }
}

.stickers5 {
  .wrapper1 {
    top: -62px;
    left: -225px;

    @include breakpoint('M') {
      left: -215px;
    }
  }

  .wrapper2 {
    top: 35px;
    right: -320px;

    @include breakpoint('M') {
      right: -240px;
    }
  }

  .wrapper3 {
    top: 140px;
    left: -320px;

    @include breakpoint('M') {
      left: -240px;
    }
  }

  .wrapper4 {
    top: 237px;
    right: -225px;

    @include breakpoint('M') {
      right: -215px;
    }
  }

  .wrapper5 {
    top: 342px;
    left: -225px;

    @include breakpoint('M') {
      left: -215px;
    }
  }
}

.stickers6 {
  .wrapper1 {
    top: -62px;
    left: -225px;

    @include breakpoint('M') {
      left: -215px;
    }
  }

  .wrapper2 {
    top: -62px;
    right: -225px;

    @include breakpoint('M') {
      right: -215px;
    }
  }

  .wrapper3 {
    top: 140px;
    left: -320px;

    @include breakpoint('M') {
      left: -240px;
    }
  }

  .wrapper4 {
    top: 140px;
    right: -320px;

    @include breakpoint('M') {
      right: -240px;
    }
  }

  .wrapper5 {
    top: 342px;
    left: -225px;

    @include breakpoint('M') {
      left: -215px;
    }
  }

  .wrapper6 {
    top: 342px;
    right: -225px;

    @include breakpoint('M') {
      right: -215px;
    }
  }
}

.love-loveis1 {
  background-image: url(../../images/stickers/love-2-1.png);
}

.love-loveis2 {
  background-image: url(../../images/stickers/love-2-2.png);
}

.love-loveis3 {
  background-image: url(../../images/stickers/love-2-3.png);
}

.love-loveis4 {
  background-image: url(../../images/stickers/love-2-4.png);
}

.love-loveis5 {
  background-image: url(../../images/stickers/love-2-5.png);
}

.love-loveis6 {
  background-image: url(../../images/stickers/love-2-6.png);
}

.love-penguins1 {
  background-image: url(../../images/stickers/love-3-1.png);
}

.love-penguins2 {
  background-image: url(../../images/stickers/love-3-2.png);
}

.love-penguins3 {
  background-image: url(../../images/stickers/love-3-3.png);
}

.love-penguins4 {
  background-image: url(../../images/stickers/love-3-4.png);
}

.love-penguins5 {
  background-image: url(../../images/stickers/love-3-5.png);
}

.love-penguins6 {
  background-image: url(../../images/stickers/love-3-6.png);
}

.love-words1 {
  background-image: url(../../images/stickers/love-4-1.png);
}

.love-words2 {
  background-image: url(../../images/stickers/love-4-2.png);
}

.love-words3 {
  background-image: url(../../images/stickers/love-4-3.png);
}

.love-words4 {
  background-image: url(../../images/stickers/love-4-4.png);
}

.love-words5 {
  background-image: url(../../images/stickers/love-4-5.png);
}

.love-words6 {
  background-image: url(../../images/stickers/love-4-6.png);
}

.kindness-loveis1 {
  background-image: url(../../images/stickers/kindness-2-1.png);
}

.kindness-loveis2 {
  background-image: url(../../images/stickers/kindness-2-2.png);
}

.kindness-loveis3 {
  background-image: url(../../images/stickers/kindness-2-3.png);
}

.kindness-loveis4 {
  background-image: url(../../images/stickers/kindness-2-4.png);
}

.kindness-loveis5 {
  background-image: url(../../images/stickers/kindness-2-5.png);
}

.kindness-loveis6 {
  background-image: url(../../images/stickers/kindness-2-6.png);
}

.kindness-penguins1 {
  background-image: url(../../images/stickers/kindness-3-1.png);
}

.kindness-penguins2 {
  background-image: url(../../images/stickers/kindness-3-2.png);
}

.kindness-penguins3 {
  background-image: url(../../images/stickers/kindness-3-3.png);
}

.kindness-penguins4 {
  background-image: url(../../images/stickers/kindness-3-4.png);
}

.kindness-penguins5 {
  background-image: url(../../images/stickers/kindness-3-5.png);
}

.kindness-penguins6 {
  background-image: url(../../images/stickers/kindness-3-6.png);
}

.kindness-words1 {
  background-image: url(../../images/stickers/kindness-4-1.png);
}

.kindness-words2 {
  background-image: url(../../images/stickers/kindness-4-2.png);
}

.kindness-words3 {
  background-image: url(../../images/stickers/kindness-4-3.png);
}

.kindness-words4 {
  background-image: url(../../images/stickers/kindness-4-4.png);
}

.kindness-words5 {
  background-image: url(../../images/stickers/kindness-4-5.png);
}

.kindness-words6 {
  background-image: url(../../images/stickers/kindness-4-6.png);
}

.trust-loveis1 {
  background-image: url(../../images/stickers/trust-2-1.png);
}

.trust-loveis2 {
  background-image: url(../../images/stickers/trust-2-2.png);
}

.trust-loveis3 {
  background-image: url(../../images/stickers/trust-2-3.png);
}

.trust-loveis4 {
  background-image: url(../../images/stickers/trust-2-4.png);
}

.trust-loveis5 {
  background-image: url(../../images/stickers/trust-2-5.png);
}

.trust-loveis6 {
  background-image: url(../../images/stickers/trust-2-6.png);
}

.trust-penguins1 {
  background-image: url(../../images/stickers/trust-3-1.png);
}

.trust-penguins2 {
  background-image: url(../../images/stickers/trust-3-2.png);
}

.trust-penguins3 {
  background-image: url(../../images/stickers/trust-3-3.png);
}

.trust-penguins4 {
  background-image: url(../../images/stickers/trust-3-4.png);
}

.trust-penguins5 {
  background-image: url(../../images/stickers/trust-3-5.png);
}

.trust-penguins6 {
  background-image: url(../../images/stickers/trust-3-6.png);
}

.trust-words1 {
  background-image: url(../../images/stickers/trust-4-1.png);
}

.trust-words2 {
  background-image: url(../../images/stickers/trust-4-2.png);
}

.trust-words3 {
  background-image: url(../../images/stickers/trust-4-3.png);
}

.trust-words4 {
  background-image: url(../../images/stickers/trust-4-4.png);
}

.trust-words5 {
  background-image: url(../../images/stickers/trust-4-5.png);
}

.trust-words6 {
  background-image: url(../../images/stickers/trust-4-6.png);
}

.understanding-loveis1 {
  background-image: url(../../images/stickers/understanding-2-1.png);
}

.understanding-loveis2 {
  background-image: url(../../images/stickers/understanding-2-2.png);
}

.understanding-loveis3 {
  background-image: url(../../images/stickers/understanding-2-3.png);
}

.understanding-loveis4 {
  background-image: url(../../images/stickers/understanding-2-4.png);
}

.understanding-loveis5 {
  background-image: url(../../images/stickers/understanding-2-5.png);
}

.understanding-loveis6 {
  background-image: url(../../images/stickers/understanding-2-6.png);
}

.understanding-penguins1 {
  background-image: url(../../images/stickers/understanding-3-1.png);
}

.understanding-penguins2 {
  background-image: url(../../images/stickers/understanding-3-2.png);
}

.understanding-penguins3 {
  background-image: url(../../images/stickers/understanding-3-3.png);
}

.understanding-penguins4 {
  background-image: url(../../images/stickers/understanding-3-4.png);
}

.understanding-penguins5 {
  background-image: url(../../images/stickers/understanding-3-5.png);
}

.understanding-penguins6 {
  background-image: url(../../images/stickers/understanding-3-6.png);
}

.understanding-words1 {
  background-image: url(../../images/stickers/understanding-4-1.png);
}

.understanding-words2 {
  background-image: url(../../images/stickers/understanding-4-2.png);
}

.understanding-words3 {
  background-image: url(../../images/stickers/understanding-4-3.png);
}

.understanding-words4 {
  background-image: url(../../images/stickers/understanding-4-4.png);
}

.understanding-words5 {
  background-image: url(../../images/stickers/understanding-4-5.png);
}

.understanding-words6 {
  background-image: url(../../images/stickers/understanding-4-6.png);
}

.loyalty-loveis1 {
  background-image: url(../../images/stickers/loyalty-2-1.png);
}

.loyalty-loveis2 {
  background-image: url(../../images/stickers/loyalty-2-2.png);
}

.loyalty-loveis3 {
  background-image: url(../../images/stickers/loyalty-2-3.png);
}

.loyalty-loveis4 {
  background-image: url(../../images/stickers/loyalty-2-4.png);
}

.loyalty-loveis5 {
  background-image: url(../../images/stickers/loyalty-2-5.png);
}

.loyalty-loveis6 {
  background-image: url(../../images/stickers/loyalty-2-6.png);
}

.loyalty-penguins1 {
  background-image: url(../../images/stickers/loyalty-3-1.png);
}

.loyalty-penguins2 {
  background-image: url(../../images/stickers/loyalty-3-2.png);
}

.loyalty-penguins3 {
  background-image: url(../../images/stickers/loyalty-3-3.png);
}

.loyalty-penguins4 {
  background-image: url(../../images/stickers/loyalty-3-4.png);
}

.loyalty-penguins5 {
  background-image: url(../../images/stickers/loyalty-3-5.png);
}

.loyalty-penguins6 {
  background-image: url(../../images/stickers/loyalty-3-6.png);
}

.loyalty-words1 {
  background-image: url(../../images/stickers/loyalty-4-1.png);
}

.loyalty-words2 {
  background-image: url(../../images/stickers/loyalty-4-2.png);
}

.loyalty-words3 {
  background-image: url(../../images/stickers/loyalty-4-3.png);
}

.loyalty-words4 {
  background-image: url(../../images/stickers/loyalty-4-4.png);
}

.loyalty-words5 {
  background-image: url(../../images/stickers/loyalty-4-5.png);
}

.loyalty-words6 {
  background-image: url(../../images/stickers/loyalty-4-6.png);
}

.respect-loveis1 {
  background-image: url(../../images/stickers/respect-2-1.png);
}

.respect-loveis2 {
  background-image: url(../../images/stickers/respect-2-2.png);
}

.respect-loveis3 {
  background-image: url(../../images/stickers/respect-2-3.png);
}

.respect-loveis4 {
  background-image: url(../../images/stickers/respect-2-4.png);
}

.respect-loveis5 {
  background-image: url(../../images/stickers/respect-2-5.png);
}

.respect-loveis6 {
  background-image: url(../../images/stickers/respect-2-6.png);
}

.respect-penguins1 {
  background-image: url(../../images/stickers/respect-3-1.png);
}

.respect-penguins2 {
  background-image: url(../../images/stickers/respect-3-2.png);
}

.respect-penguins3 {
  background-image: url(../../images/stickers/respect-3-3.png);
}

.respect-penguins4 {
  background-image: url(../../images/stickers/respect-3-4.png);
}

.respect-penguins5 {
  background-image: url(../../images/stickers/respect-3-5.png);
}

.respect-penguins6 {
  background-image: url(../../images/stickers/respect-3-6.png);
}

.respect-words1 {
  background-image: url(../../images/stickers/respect-4-1.png);
}

.respect-words2 {
  background-image: url(../../images/stickers/respect-4-2.png);
}

.respect-words3 {
  background-image: url(../../images/stickers/respect-4-3.png);
}

.respect-words4 {
  background-image: url(../../images/stickers/respect-4-4.png);
}

.respect-words5 {
  background-image: url(../../images/stickers/respect-4-5.png);
}

.respect-words6 {
  background-image: url(../../images/stickers/respect-4-6.png);
}
